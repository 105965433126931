<template>
  <div >
    <div class="text-center" style="height: 90vh; padding-top: 0; margin-top: 0">
      <PageComingSoon/>
    </div>
    <!-- <termsandconditions v-if="hasAcceptedLatestTerms"
      :showDialog.sync="showTerms"
      :content="termsContent"
      :version="termsVersion"
      @close="onTermsClosed"
      @accept-terms="onTermsAccepted"
    /> -->
  </div>
</template>

<script>
// import Termsandconditions from '../TermsAndConditions/LatestTermsAndConditions.vue';
import PageComingSoon from '@/components/SystemComponents/PageComingSoon';
export default {
  components: {
    PageComingSoon,
    // Termsandconditions
  },
  data: () => ({
    loading: true,

    showTerms: false,
    termsContent: '',
    termsVersion: '',
    hasAcceptedLatestTerms: false,

  }),
  mounted() {
  // this.checkUserAcceptance();
  },
  methods: {

    //Methods for checking what version of Terms and conditions users have accepted 
    async checkUserAcceptance() {
      try {
        const response = await this.$API.checkUserAcceptance(
          this.$store.state.user.id,
          this.$store.state.currentOrg.id
        );

        if (response.accepted) {
          this.hasAcceptedLatestTerms = true;
        } else {
          const termsResponse = await this.$API.getTerms();
          this.termsContent = termsResponse.content;
          this.termsVersion = termsResponse.version;
          this.showTerms = true;
        }
      } catch (error) {
        console.error('Error checking user acceptance:', error);
        this.$router.push('/login');
      }
    },

    async onTermsAccepted() {
      try {
        await this.$API.acceptTermsAndConditions({
          userId: this.$store.state.user.id,
          organisationId: this.$store.state.currentOrg.id,
        });
        this.hasAcceptedLatestTerms = true;
        this.showTerms = false;
      } catch (error) {
        console.error('Error accepting terms and conditions:', error);
      }
    },

    onTermsClosed(version) {
      console.log(`Terms and conditions version ${version} closed without acceptance.`);
      this.clearState();
      this.$router.replace('/login'); 
    },

    clearState() {
      // Reset local component state
      this.hasAcceptedLatestTerms = false;
      this.showTerms = false;
      this.termsContent = '';
      this.termsVersion = '';

      // Clear Vuex store state
      this.$store.dispatch('resetState'); 
    }
    
  },
}
</script>

<style scoped>

@keyframes square-animation {
 0% {
  left: 0;
  top: 0;
 }

 10.5% {
  left: 0;
  top: 0;
 }

 12.5% {
  left: 32px;
  top: 0;
 }

 23% {
  left: 32px;
  top: 0;
 }

 25% {
  left: 64px;
  top: 0;
 }

 35.5% {
  left: 64px;
  top: 0;
 }

 37.5% {
  left: 64px;
  top: 32px;
 }

 48% {
  left: 64px;
  top: 32px;
 }

 50% {
  left: 32px;
  top: 32px;
 }

 60.5% {
  left: 32px;
  top: 32px;
 }

 62.5% {
  left: 32px;
  top: 64px;
 }

 73% {
  left: 32px;
  top: 64px;
 }

 75% {
  left: 0;
  top: 64px;
 }

 85.5% {
  left: 0;
  top: 64px;
 }

 87.5% {
  left: 0;
  top: 32px;
 }

 98% {
  left: 0;
  top: 32px;
 }

 100% {
  left: 0;
  top: 0;
 }
}

.loader {
 position: relative;
 width: 96px;
 height: 96px;
 transform: rotate(45deg);
}

.loader-square {
 position: absolute;
 top: 0;
 left: 0;
 width: 28px;
 height: 28px;
 margin: 2px;
 border-radius: 0px;
 background: white;
 background-size: cover;
 background-position: center;
 background-attachment: fixed;
 animation: square-animation 10s ease-in-out infinite both;
}

.loader-square:nth-of-type(0) {
 animation-delay: 0s;
}

.loader-square:nth-of-type(1) {
 animation-delay: -1.4285714286s;
}

.loader-square:nth-of-type(2) {
 animation-delay: -2.8571428571s;
}

.loader-square:nth-of-type(3) {
 animation-delay: -4.2857142857s;
}

.loader-square:nth-of-type(4) {
 animation-delay: -5.7142857143s;
}

.loader-square:nth-of-type(5) {
 animation-delay: -7.1428571429s;
}

.loader-square:nth-of-type(6) {
 animation-delay: -8.5714285714s;
}

.loader-square:nth-of-type(7) {
 animation-delay: -10s;
}


html, body {
  margin: 0;
  padding: 0;
}
body {
  background: #000;
  background: rgba(0, 0, 0, 1);
  overflow-x: hidden; 
}

/* ---------- Fog ---------- */
.fogwrapper {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
  filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
}
#foglayer_01, #foglayer_02, #foglayer_03 {
  height: 100%;
  position: absolute;
  width: 200%;
}
#foglayer_01 .image01, #foglayer_01 .image02,
#foglayer_02 .image01, #foglayer_02 .image02,
#foglayer_03 .image01, #foglayer_03 .image02 {
  float: left;
  height: 100%;
  width: 50%;
}
#foglayer_01 {
  -webkit-animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
  -moz-animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
  animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
}
#foglayer_02, #foglayer_03 {
  -webkit-animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
  -moz-animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
  animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
}

/* ---------- Moving Fog ---------- */
/*
  'size: cover' || 'size: 100%'; results remain the same
  'attachment: scroll' can be added or removed; results remain the same
  'attachment: fixed' causing unexpected results in Chrome
  'repeat-x' || 'no-repeat'; results remain the same
*/ 
#foglayer_01 .image01, #foglayer_01 .image02 {
  background: url("https://raw.githubusercontent.com/danielstuart14/CSS_FOG_ANIMATION/master/fog1.png") center center/cover no-repeat transparent;
}
#foglayer_02 .image01, #foglayer_02 .image02,
#foglayer_03 .image01, #foglayer_03 .image02{
  background: url("https://raw.githubusercontent.com/danielstuart14/CSS_FOG_ANIMATION/master/fog2.png") center center/cover no-repeat transparent;
}

/* ---------- Keyframe Layer 1 ---------- */
@-webkit-keyframes foglayer_01_opacity {
  0% { opacity: .1; }
  22% { opacity: .5; }
  40% { opacity: .28; }
  58% { opacity: .4; }
  80% { opacity: .16; }
  100% { opacity: .1; }
}
@-moz-keyframes foglayer_01_opacity {
  0% { opacity: .1; }
  22% { opacity: .5; }
  40% { opacity: .28; }
  58% { opacity: .4; }
  80% { opacity: .16; }
  100% { opacity: .1; }
}
@-o-keyframes foglayer_01_opacity {
  0% { opacity: .1; }
  22% { opacity: .5; }
  40% { opacity: .28; }
  58% { opacity: .4; }
  80% { opacity: .16; }
  100% { opacity: .1; }
}
@keyframes foglayer_01_opacity {
  0% { opacity: .1; }
  22% { opacity: .5; }
  40% { opacity: .28; }
  58% { opacity: .4; }
  80% { opacity: .16; }
  100% { opacity: .1; }
}
/* ---------- Keyframe Layer 2 ---------- */
@-webkit-keyframes foglayer_02_opacity {
  0% { opacity: .5; }
  25% { opacity: .2; }
  50% { opacity: .1; }
  80% { opacity: .3; }
  100% { opacity: .5; }
}
@-moz-keyframes foglayer_02_opacity {
  0% { opacity: .5; }
  25% { opacity: .2; }
  50% { opacity: .1; }
  80% { opacity: .3; }
  100% { opacity: .5; }
}
@-o-keyframes foglayer_02_opacity {
  0% { opacity: .5; }
  25% { opacity: .2; }
  50% { opacity: .1; }
  80% { opacity: .3; }
  100% { opacity: .5; }
}
@keyframes foglayer_02_opacity {
  0% { opacity: .5; }
  25% { opacity: .2; }
  50% { opacity: .1; }
  80% { opacity: .3; }
  100% { opacity: .5; }
}
/* ---------- Keyframe Layer 3 ---------- */
@-webkit-keyframes foglayer_03_opacity {
  0% { opacity: .8 }
  27% { opacity: .2; }
  52% { opacity: .6; }
  68% { opacity: .3; }
  100% { opacity: .8; }
}
@-moz-keyframes foglayer_03_opacity {
  0% { opacity: .8 }
  27% { opacity: .2; }
  52% { opacity: .6; }
  68% { opacity: .3; }
  100% { opacity: .8; }
}
@-o-keyframes foglayer_03_opacity {
  0% { opacity: .8 }
  27% { opacity: .2; }
  52% { opacity: .6; }
  68% { opacity: .3; }
  100% { opacity: .8; }
}
@keyframes foglayer_03_opacity {
  0% { opacity: .8; }
  27% { opacity: .2; }
  52% { opacity: .6; }
  68% { opacity: .3; }
  100% { opacity: .8; }
}
/* ---------- Keyframe moveMe ---------- */
@-webkit-keyframes foglayer_moveme {
  0% { left: 0; }
  100% { left: -100%; }
}
@-moz-keyframes foglayer_moveme {
  0% { left: 0; }
  100% { left: -100%; }
}
@-o-keyframes foglayer_moveme {
  0% { left: 0; }
  100% { left: -100%; }
}
@keyframes foglayer_moveme {
  0% { left: 0; }
  100% { left: -100%; }
}

@media only screen
  and (min-width: 280px)
  and (max-width: 767px) {
    #foglayer_01 .image01, #foglayer_01 .image02,
    #foglayer_02 .image01, #foglayer_02 .image02,
    #foglayer_03 .image01, #foglayer_03 .image02 {
      width: 100%;
    }
  }
</style>
